import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import { withPrefix, Link } from 'gatsby';
import Img from "gatsby-image";
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import RichText from '../components/richText';


const MediosDePago = (props) => {

  const showCrediton = true;
  const assets = 'assets'


  //Metadata
  const $metadata = props.data.prismic.allMetadatas.edges[0].node;

  //Footer
  const $footer = { data: props.data.prismic.allFooters.edges[0].node, infoPopup: props.data.prismic.allInformacion_financieras.edges[0].node }
  const $layoutData = { $metadata, $footer };

  //Metodos
  const $metodosList = props.data.prismic.allMedios_de_pagos.edges[0].node.body;

  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);


  return (

    <>

      <Layout data={$layoutData} footer={true} className={'u-medios-de-pago'} >


        <SEO metadata={$metadata} title={'Medios de pago'}></SEO>

        <>


          <Helmet>

            <link href={withPrefix(`../../${assets}/plugins/tiny-slider/tiny-slider.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/tiny-slider-custom.css?v=2`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/icons.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/fonts.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/screen.css?v=10`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/form.css?v=4`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/financial-info.css?v=5`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/payment.css?v=8`)} rel="stylesheet" type="text/css" />


          </Helmet>

          {/* HERO */}
          <div className="c-hero o-align-middle">
            <div className="c-hero__holder o-align-middle__aligner o-section">
              <div className="o-wrapper">
                <div className="js-header-waypoints" id="c-header-waypoint" />
                <h2 className={`c-title c-title--big c-title--white c-hero__title `}>Pagá tus cuotas <br /> 100% <strong className="c-title--outline c-title--outline-white">online</strong></h2>
                <span className={`c-text c-text--big c-title--white `}>Conocé paso a paso cómo pagar tu cuota desde los diferentes medios digitales</span>
                <ul className="c-hero__list">

                  {$metodosList.map((field, i) => (

                    <li key={i} className="c-hero__item"><span className="u-hidden-for-seo">{field.primary.titulo[0].text}</span><a href={`#c-method--${i}`} className="c-method__link js-method__link" style={{ backgroundImage: `url("${field.primary.icono.url}")` }} /></li>

                  ))}

                </ul>
              </div>
            </div>{/* .c-hero__holder */}
          </div>{/* .c-hero */}


          {$metodosList.length > 0 && hasMounted &&

            <Helmet>

              <script src="/assets/plugins/tiny-slider/tiny-slider.js"></script>
              <script src="/assets/js/sitio/method.js?v=5"></script>

            </Helmet>

          }

          {$metodosList.length > 0 && hasMounted &&
            $metodosList.map((field, i) => (
              <section key={i} className="c-method" id={`c-method--${i}`} >
                <h2 className="u-hidden-for-seo">{field.primary.titulo[0].text}</h2>
                <div className={`c-method__holder ${field.fields && field.fields[0].imagen_desktop ? 'js-devices' : ''} js-showing-mobile o-section`}>
                  <div className="c-method__info o-wrapper">

                    <div className="c-method__title">
                      <span className="c-title c-title--small">
                        <span className="c-method__link c-method__link--xs c-method__link--redpagos" style={{ backgroundImage: `url("${field.primary.icono.url}")` }} />
                        {field.primary.titulo[0].text}
                      </span>
                    </div>


                    {/* Only desktop */}
                    {field.fields && field.fields[0].imagen_desktop &&
                      <div className="c-devices">
                        <button className="c-method__btn c-method__btn--mobile c-btn is-selected js-mobile" disabled="disabled">Celular</button>{/*
                        */}<button className="c-method__btn c-method__btn--pc c-btn js-computer">Computadora</button>
                      </div>
                    }


                    <ol className="c-method__list c-method__mobile">

                      {field.fields.map((step, ix) => (
                        step.step && 
                        <li className={`c-method__step c-text ${ix == 0 ? 'is-active' : ''}`} data-step={ix}>
                          <span className="c-method__number">{ix + 1}.</span>
                          <span className="c-method__text">

                            <RichText render={step.step} />

                          </span>

                          {ix == 0 &&
                            <div className="c-method__apps">
                              {field.primary.link_google && <a href={field.primary.link_google.url} target="_blank" className="c-method__app-link c-method__app-link--googleplay" rel="noreferrer" />}
                              {field.primary.link_apple && <a href={field.primary.link_apple.url} target="_blank" className="c-method__app-link c-method__app-link--applestore" rel="noreferrer" />}
                            </div>
                          }

                        </li>
                      ))}

                    </ol>

                    {/* Only desktop */}
                    {field.fields && field.fields[0].imagen_desktop &&

                      <ol className="c-method__list c-method__pc">

                        {field.fields.map((step, ix) => (
                          step.step_desktop && 
                          <li key={ix} className={`c-method__step c-text ${ix == 0 ? 'is-active' : ''}`} data-step={ix}>
                            <span className="c-method__number">{ix + 1}.</span>
                            <span className="c-method__text">

                              <RichText render={step.step_desktop} />

                            </span>


                          </li>
                        ))}

                      </ol>
                    }


                  </div>
                  {' '}

                  {field.fields && field.fields[0].imagen_mobile &&
                    <div className="c-method__images c-method__images--mobile">

                      <div className="c-method__carousel js-method-carousel js-carousel__notInit">
                        <div className="c-carousel__viewport">
                          <ul className="js-carousel">
                            {field.fields.map((image, im) => (

                              image.imagen_mobile &&
                              <li key={im} style={{ backgroundImage: `url(${image.imagen_mobile.url})` }} className={`c-method__screen ${im != 0 ? 'js-carousel__notInit--notInitHide' : ''} `} />

                            ))}

                          </ul>
                        </div>
                      </div>
                    </div>
                  }


                  {/* Only desktop */}
                  {field.fields && field.fields[0].imagen_desktop &&
                    <div className="c-method__images c-method__images--desktop">
                      <div className="c-method__carousel c-method__carousel--desktop js-method-carousel js-carousel__notInit">
                        <div className="c-carousel__viewport">
                          <ul className="js-carousel">
                            {field.fields.map((image, im) => (

                              image.imagen_desktop &&
                              <li key={im} style={{ backgroundImage: `url(${image.imagen_desktop.url})` }} className={`c-method__screen ${im != 0 ? 'js-carousel__notInit--notInitHide' : ''} `} />

                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  }



                </div>
              </section>

            ))}

        </>


      </Layout>


    </>
  );
}



export const query = graphql`
query MediosDePagoQuery {
          prismic {
        allMetadatas  {
          edges {
        node {
          ...MetadataFragment
        }
        }
      },
    allFooters {
          edges {
        node {
          ...FooterFragment
        }
        }
      },
    allMedios_de_pagos {
          edges {
        node {
          body {
        ... on PRISMIC_Medios_de_pagoBodyMetodo {
          fields {
        imagen_desktop
        imagen_mobile
        step
        step_desktop
      }
              primary {
                link_apple {
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
                link_google {
                  ... on PRISMIC__ExternalLink {
                    
                    url
                  }
                }
          titulo
          icono
          background
        }
      }
    }
  }
}
},
allInformacion_financieras {
  edges {
    node {
      ...InformacionFinancieraFragment
    }
  }
}

}
}
`


export default MediosDePago;

